<template>
    <div class="authorize-container">
        <div class="left-side">
            <div class="promo-content">
                <div class="invert-container">
                    <img src="/img/EMlogo-large1x.png" class="logo" alt="Logo" />
                </div>
                <h2 style="font-family: 'MiSans-Bold', Arial, sans-serif;">授权请求</h2>
            </div>
        </div>

        <div class="right-side">
            <!-- 卡片切换 -->
            <transition name="cardTransition" mode="out-in" @before-enter="beforeEnterCard" @enter="enterCard"
                @before-leave="beforeLeaveCard" @leave="leaveCard">
                <div :key="phase" class="mdui-card authorize" ref="cardContainer">
                    <!-- 阶段 0: 加载中 -->
                    <div v-if="phase === 0">
                        <div class="loading-container">
                            <div class="spinner"></div>
                        </div>
                    </div>

                    <!-- 阶段 1: 欢迎 -->
                    <div v-else-if="phase === 1">
                        <div class="welcome-section">
                            <img v-if="userAvatar" :src="userAvatar" alt="User Avatar" class="user-avatar" />
                            <h2 class="welcome-title">你好，{{ userDisplayName }}</h2>
                        </div>
                    </div>

                    <!-- 阶段 2: 授权请求 -->
                    <div v-else-if="phase === 2">
                        <h2 class="authorize-title">授权应用</h2>

                        <!-- 应用图标 + 名称 同行、垂直居中 -->
                        <p class="app-info-line">
                            <img :src="authRequest.client.logoUrl" class="app-icon" alt="应用头像" />
                            <span class="app-name">{{ clientName }}</span>
                        </p>

                        <div v-if="authRequest && clientName">
                            <p>该应用请求获取并访问你的 VDS Entertainment Network 账户信息。</p>
                            <p>它的权限范围: {{ scopeDescription }}</p>

                            <button @click="handleApprove">
                                同意并继续前往 {{ clientName }}
                            </button>
                            <button @click="handleDeny">拒绝</button>

                            <!-- 跳转到 /logout -->
                            <p class="logout-hint" @click="handleLogout">
                                我不是 {{ userDisplayName }}
                            </p>
                        </div>
                        <div v-else>
                            <p>加载中...</p>
                        </div>
                    </div>

                    <!-- 阶段 3: 拒绝 -->
                    <div v-else-if="phase === 3">
                        <div class="deny-section">
                            <!-- <img src="/img/placeholder_500x500.png" alt="Placeholder"
                                style="width: 500px; height: 500px; object-fit: cover;" /> -->
                            <p class="deny-text">
                                你已拒绝授权，服务无法获取你的信息。<br />
                                你可以安全地关闭此页面。
                            </p>
                        </div>
                    </div>

                    <!-- 阶段 9: 全局错误 -->
                    <div v-else-if="phase === 9">
                        <h2 class="error-title">发生错误</h2>
                        <p>错误内容为: {{ error }}</p>
                        <p>
                            这不是你的问题，可能是应用的开发者并没有正确接入
                            VDS Entertainment Network。请联系应用开发者修复问题。
                        </p>
                        <!-- <p style="color: gray;">仍然有疑问? 可以看看我们提供的帮助链接</p>
                        <button>常见问题解答</button> -->
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { gsap } from 'gsap'

export default {
    name: 'AuthorizePage',
    data() {
        return {
            phase: 0, // 0=加载,1=欢迎,2=授权,3=拒绝, 9=全局错误
            authRequest: null,
            clientName: '',
            error: '',
            userAvatar: '',
            userDisplayName: ''
        }
    },
    computed: {
        scopeDescription() {
            if (!this.authRequest) return ''
            return this.authRequest.scope === 'read'
                ? '读取你的所有信息'
                : this.authRequest.scope
        }
    },
    async created() {
        try {
            // 获取用户信息
            const token = localStorage.getItem('token')
            if (token) {
                const userResp = await axios.get('/userinfo', {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                if (userResp.data) {
                    this.userAvatar = userResp.data.avatarUrl || '/img/EMlogo-large1x.png'
                    this.userDisplayName =
                        userResp.data.nickname || userResp.data.username || userResp.data.email || '用户'
                }
            }

            // 解析 URL
            const urlParams = new URLSearchParams(window.location.search)
            const clientID = urlParams.get('client_id')
            const redirectURI = urlParams.get('redirect_uri')
            const scope = urlParams.get('scope')
            const responseType = urlParams.get('response_type')
            const state = urlParams.get('state')

            if (!clientID || !redirectURI || !scope || !responseType) {
                // 出错 => 直接进入 phase=9
                this.error = '缺少必要的授权参数'
                this.phase = 9
                return
            }
            this.clientName = clientID

            // 请求授权信息
            const resp = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/oauth/authorize`, {
                params: {
                    client_id: clientID,
                    redirect_uri: redirectURI,
                    scope: scope,
                    response_type: responseType,
                    state: state
                }
            })

            if (resp.data && resp.data.client) {
                this.authRequest = {
                    client: resp.data.client,
                    scope: resp.data.scope,
                    state: resp.data.state,
                    transactionID: resp.data.transactionID,
                    clientID: clientID,
                    redirectURI: redirectURI
                }
                // 进入欢迎
                this.phase = 1
                // 3秒后切授权请求
                setTimeout(() => {
                    if (this.phase === 1) {
                        this.phase = 2
                    }
                }, 3000)
            } else {
                this.error = '未能正确获取授权请求信息'
                this.phase = 9
            }
        } catch (err) {
            this.error = err.response?.data?.error || '授权请求失败'
            this.phase = 9
        }
    },
    methods: {
        // 点击“我不是xxx”，跳转到 /logout
        handleLogout() {
            // 如果当前路由有 next 参数，就把它传给 /logout
            const next = this.$route.query.next
            // 跳转到 LogoutPage (包括 next)
            if (next) {
                this.$router.push({ path: '/logout', query: { next } })
            } else {
                this.$router.push('/logout')
            }
        },
  
      async handleApprove() {
            try {
                const token = localStorage.getItem('token')
                const resp = await this.$http.post(
                    `${process.env.VUE_APP_API_BASE_URL}/oauth/authorize/decision`,
                    {
                        allow: true,
                        transactionID: this.authRequest.transactionID,
                        clientId: this.authRequest.clientID,
                        redirectUri: this.authRequest.redirectURI
                    },
                    { headers: { 'Authorization': `Bearer ${token}` } }
                )
                // 跳转
                window.location.href = `${this.authRequest.redirectURI}?code=${resp.data.code}`
            } catch (err) {
                this.error = err.response?.data?.error || '授权失败'
            }
        },
        async handleDeny() {
            try {
                this.phase = 3
                const token = localStorage.getItem('token')
                await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/oauth/authorize/decision`, {
                    allow: false,
                    transactionID: this.authRequest.transactionID,
                    clientId: this.authRequest.clientID,
                    redirectUri: this.authRequest.redirectURI
                }, {
                    headers: { 'Authorization': `Bearer ${token}` }
                })
            } catch (err) {
                this.error = err.response?.data?.error || '拒绝授权失败'
            }
        },

        /* 卡片出现: 0-> (scrollHeight - 30) */
        async enterCard(el, done) {
            await this.$nextTick()
            let finalHeight = el.scrollHeight - 30
            finalHeight = Math.max(0, finalHeight)

            gsap.fromTo(
                el,
                { height: 0, opacity: 0 },
                {
                    height: finalHeight,
                    opacity: 1,
                    duration: 0.5,
                    ease: 'power2.out',
                    onComplete: done
                }
            )
        },
        beforeLeaveCard(el) {
            let current = parseFloat(window.getComputedStyle(el).height)
            el.style.height = current + 'px'
        },
        leaveCard(el, done) {
            gsap.to(el, {
                height: 0,
                opacity: 0,
                duration: 0.4,
                ease: 'power2.inOut',
                onComplete: done
            })
        }
    }
}
</script>

<style scoped>
.authorize-container {
    margin-top: 55px;
    display: flex;
    height: calc(100vh - 55px);
    overflow: hidden;
}

.left-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.promo-content {
    text-align: center;
    color: white;
}

.logo {
    height: 50px;
}

.mdui-card {
    color: white;
    padding: 20px;
    max-width: 400px;
    width: 100%;
    background-color: rgba(47, 47, 47, 0.3);
    border-radius: 15px;
}

/* loading */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255, 255, 255, 0.2);
    border-top: 6px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

/* 欢迎阶段 */
.welcome-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.welcome-title {
    margin-bottom: 5px;
    font-family: 'MiSans-Bold', Arial, sans-serif;
}

/* 拒绝阶段 */
.deny-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.deny-text {
    margin-top: 20px;
    text-align: center;
    line-height: 1.5;
}

/* 全局错误阶段 (phase=9) */
.error-title {
    font-family: 'MiSans-Bold', Arial, sans-serif;
}

/* 应用信息行：图标+名字 */
.app-info-line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.app-icon {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 8px;
}

.app-name {
    font-size: 1.1em;
}

/* “我不是xxx” */
.logout-hint {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
}

/* 默认按钮样式 */
button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

.authorize-title {
    font-family: 'MiSans-Bold', Arial, sans-serif;
}

/* 小屏幕适配 */
@media (max-width: 1000px) {
  .left-side {
    display: none;
  }
  .right-side {
    flex: 1;
    padding: 10px;
  }
  .mdui-card {
    max-width: 90%;
  }
}
</style>